<template>
  <span v-if="actual">
    {{ actual | formatDate }}
  </span>
  <span v-else class="expected">
    {{ expected | formatDate }}
  </span>
</template>

<script>


export default {
  name: 'ValueState',
  props: {
    actual: null,
    expected: null
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  span.expected {
      font-style: italic;
  }
</style>
