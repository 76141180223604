<template>
  <v-card
    :elevation="isClosed ? 0 : 2"
    outlined
    class="text--secondary"
    :to="to"
  >
    <v-toolbar
      flat
      :dense="dense"
      :color="!$vuetify.theme.dark ? 'grey lighten-4' : ''"
    >
      <v-toolbar-title class="d-flex align-center">
        <v-btn
          class="d-none d-sm-block mr-3"
          :color="color"
          elevation="0"
          fab
          small
        >
          <v-icon dark>
            {{ icon }}
          </v-icon>
        </v-btn>

        <span
          :class="[ $vuetify.breakpoint.mobile ? 'text-body-1' : 'text-h6' ]"
        >
          {{ item.code }} {{ item.locationTitle }}
        </span>

        <RelevantMarker v-if="item.isRelevant" />
      </v-toolbar-title>

      <v-spacer />

      <LocationStatus
        v-if="!hideStatus && !$vuetify.breakpoint.mobile"
        :location-id="item.locationId"
        :case-id="item.id"
        small
      />
    </v-toolbar>

    <v-row no-gutters>
      <v-col
        cols="12"
        md="5"
      >
        <v-card-title>
          <slot name="title">
            <div v-if="item.periodHtmlString">
              {{ item.periodHtmlString }}
            </div>
            <div
              v-else-if="item.startedOn || item.expectedStart || item.endedOn || item.expectedEnd"
              class="d-flex align-center"
            >
              <ValueState
                :actual="item.startedOn"
                :expected="item.expectedStart"
              />
              <v-icon
                class="mx-2"
                small
              >
                mdi-arrow-right
              </v-icon>
              <ValueState
                :actual="item.endedOn"
                :expected="item.expectedEnd"
              />
            </div>
          </slot>
        </v-card-title>

        <v-card-subtitle class="text-h6">
          {{ item.title }}
        </v-card-subtitle>

        <v-card-text>
          <div
            v-if="!hideEstimates"
            class="d-flex align-center mb-3 subtitle"
          >
            <v-icon class="mr-1">
              mdi-account-outline
            </v-icon>
            <div class="mr-6">
              {{ item.expectedConsultants || '-' }}
            </div>
            <v-icon class="mr-1">
              mdi-clock-outline
            </v-icon>
            <div class="mr-6">
              {{ item.expectedDays ? $options.filters.formatNumber(item.expectedDays) : '-' }}
            </div>
            <v-icon class="mr-1">
              mdi-cash
            </v-icon>
            <div class="mr-6">
              {{ item.expectedPrice ? `${$options.filters.formatNumber(item.expectedPrice)} ${$t('currency.dkk')}` : '-' }}
            </div>
          </div>

          <table>
            <tbody>
              <tr v-if="types">
                <td class="pr-2">
                  Type
                </td>
                <td class="pa-1">
                  <v-chip
                    v-for="item in types.filter(x => activeTypes.includes(x.value))"
                    :key="item.value"
                    class="mr-2"
                    small
                    label
                  >
                    {{ item.text }}
                  </v-chip>
                </td>
              </tr>

              <tr v-if="departments">
                <td class="pr-2">
                  Enheder
                </td>
                <td class="pa-1">
                  <v-chip
                    v-for="item in departments.filter(x => activeDepartments.includes(x.value))"
                    :key="item.value"
                    class="mr-2"
                    small
                    label
                  >
                    {{ item.text }}
                  </v-chip>
                </td>
              </tr>

              <tr v-if="subjectAreas">
                <td class="pr-2">
                  Tekniske områder
                </td>
                <td class="pa-1">
                  <v-chip
                    v-for="item in subjectAreas.filter(x => activeSubjectAreas.includes(x.value))"
                    :key="item.value"
                    class="mr-2"
                    small
                    label
                  >
                    {{ item.text }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text>
          <Status
            v-if="statuses"
            :statuses="statuses"
            :status-id="item.statusId"
            :is-closed="isClosed"
            class="mb-2"
          />
          <slot name="body" />
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Status from '@/components/Status.vue';
import RelevantMarker from '@/components/RelevantMarker.vue';
import ValueState from '@/components/ValueState.vue';
import LocationStatus from '@/components/LocationStatus';

export default {
  name: 'CaseCard',
  components: {
    Status,
    RelevantMarker,
    ValueState,
    LocationStatus
  },
  props: {
    color: {
      type: String,
      default: () => 'primary'
    },
    icon: {
      type: String,
      required: true
    },
    item: Object,
    statuses: Array,
    to: String,
    types: Array,
    departments: Array,
    subjectAreas: Array,
    activeTypes: {
      type: Array,
      default: () => []
    },
    activeDepartments: {
      type: Array,
      default: () => []
    },
    activeSubjectAreas: {
      type: Array,
      default: () => []
    },
    hideEstimates: {
      type: Boolean,
      default: () => false
    },
    hideStatus: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    isClosed() {
      return !!this.item.closedOn;
    }
  }
};
</script>

<style scoped>
.ticketHeadCol {
  min-height: 255px;
}
.focusPoints {
  padding-top: 8px;
  padding-left: 8px;
}
</style>
