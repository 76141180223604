<template>
  <v-chip
    class="ml-3"
    color="teal"
    small
    dark
  >
    <v-icon small>
      mdi-account
    </v-icon>
  </v-chip>
</template>

<script>
export default {
  name: 'RelevantMarker'
}
</script>