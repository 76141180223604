<template>
  <v-menu
    v-model="menu"
    open-on-hover
    :close-on-content-click="false"
    left
    bottom
    origin="top right"
    :min-width="hasOtherCases ? 600 : 300"
    nudge-bottom="56"
  >
    <template #activator="{ on, attrs }">
      <v-sheet
        color="transparent"
        class="d-flex justify-center align-center"
        :class="{ 'flex-column': vertical }"
        height="100%"
        v-on="on"
        v-bind="attrs"
        v-intersect.once="onIntersect"
      >
        <slot />

        <v-badge
          v-for="type in types"
          :key="type.name"
          :value="type.items.length"
          :content="type.items.length"
          :label="type.name"
          bordered
          overlap
          offset-y="14"
          offset-x="14"
        >
          <v-btn
            :loading="loading"
            :color="type.color"
            elevation="0"
            fab
            :small="!small"
            :x-small="small"
            :class="{ 'mx-2': !vertical, 'my-2': vertical }"
          >
            <v-icon dark>
              {{ type.icon }}
            </v-icon>
          </v-btn>
        </v-badge>
      </v-sheet>
    </template>

    <v-card v-if="hasOtherCases">
      <v-card-title>
        Øvrige sager på lokationen
      </v-card-title>

      <v-card-text id="case-list">
        <template v-for="(type, index) in types">
          <v-list
            v-if="type.items.length"
            :key="type.name"
            subheader
            two-line
          >
            <v-subheader :key="type + index">
              {{ type.name }}
            </v-subheader>

            <template v-for="(item, index) in type.items">
              <v-list-item
                :key="item.id"
                :disabled="item.id === $route.params.id"
                :to="`/${type.routeSlug}/${item.id}`"
              >
                <v-list-item-avatar>
                  <v-btn
                    fab
                    :color="type.color"
                    :disabled="item.id === $route.params.id"
                  >
                    <v-icon light>
                      {{ type.icon }}
                    </v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content :class="{ 'text--disabled': item.id === $route.params.id }">
                  <v-list-item-title>
                    {{ item.code }} {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <!-- <v-chip-group>
                      <v-chip
                        v-for="department in item.departments"
                        :key="`${item.code}-department-${department}`"
                        small
                        label
                      >
                        {{ department }}
                      </v-chip>
                    </v-chip-group>
                    <v-chip-group>
                      <v-chip
                        v-for="subject in item.subjectAreas"
                        :key="`${item.code}-subject-${subject}`"
                        small
                        label
                      >
                        {{ subject }}
                      </v-chip>
                    </v-chip-group> -->
                    {{ item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="item.status"
                    label
                    :disabled="item.id === $route.params.id"
                  >
                    {{ item.status }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="type.items.length > 1 && index + 1 !== type.items.length"
                :key="'divider' + index"
                inset
              />
            </template>
          </v-list>
        </template>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-card-text class="text-center">
        Ingen øvrige sager på lokationen
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'LocationStatus',
  props: {
    locationId: {
      type: String,
      required: true
    },
    caseId: {
      type: String,
      required: true
    },
    vertical: {
      type: Boolean,
      default: () => false
    },
    small: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      menu: false,
      types: {
        supportTickets: {
          icon: 'mdi-headset',
          color: 'orange lighten-3',
          routeSlug: 'supportticket',
          name: this.$t('supportTickets.list.title'),
          items: []
        },
        taskRequests: {
          icon: 'mdi-format-list-checks',
          color: 'blue lighten-3',
          routeSlug: 'taskrequest',
          name: this.$t('taskRequests.list.title'),
          items: []
        },
        deliveries: {
          icon: 'mdi-hammer-screwdriver',
          color: 'blue-grey lighten-3',
          routeSlug: 'delivery',
          name: this.$t('deliveries.list.title'),
          items: []
        }
      }
    }
  },
  computed: {
    hasOtherCases() {
      for (const type in this.types) {
        if (this.types[type].items?.length) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchData();
      }
    },
    async fetchData() {
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get(`location/${this.locationId}/status`);

        const { supportTickets, taskRequests, deliveries } = response.data;

        this.types.supportTickets.items = supportTickets.filter(x => x.id !== this.caseId);
        this.types.taskRequests.items = taskRequests.filter(x => x.id !== this.caseId);
        this.types.deliveries.items = deliveries.filter(x => x.id !== this.caseId);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

  },
  watch: {
    caseId() {
      this.menu = false;
      this.fetchData();
    }
  }
}
</script>

<style scoped>
#case-list {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
