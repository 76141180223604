<template>
  <div
    class="d-flex align-center"
    :class="[ $vuetify.breakpoint.mobile ? 'justify-start' : 'justify-space-between' ]"
  >
    <div
      class="d-sm-none mr-3"
    >
      Status
    </div>
    <v-sheet
      v-for="(status, i) in computedStatuses"
      :key="status.value"
      class="text-center pa-2"
      :class="[ $vuetify.breakpoint.mobile ? 'flex-grow-0': 'flex-grow-1' ]"
      :color="getColor(i)"
      :elevation="i === currentStep && !isClosed ? 5 : 0"
      :rounded="i === currentStep"
    >
      <span class="text-caption"> {{ status.text }} </span>
      <v-icon v-if="i < currentStep || isClosed" right small>
        mdi-check-circle-outline
      </v-icon>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "Status",
  props: {
    statuses: Array,
    statusId: String,
    isClosed: Boolean
  },
  computed: {
    currentStep() {
      return this.statuses.findIndex(x => x.value === this.statusId);
    },
    computedStatuses() {
      if (this.isClosed) {
        return [
          {
            disabled: false,
            text: 'Afsluttet',
            value: ''
          }
        ]
      }

      if (this.$vuetify.breakpoint.mobile) {
        console.log(this.statuses[this.currentStep]);
        return [this.statuses[this.currentStep]];
      }

      else {
        return this.statuses;
      }
    }
  },
  methods: {
    getColor(index) {
      if (this.isClosed) {
        return 'grey lighten-3'
      }

      if (index === this.currentStep) {
        return this.$vuetify.theme.dark ? 'grey darken-2' : 'white';
      }
      else if (index < this.currentStep) {
        return 'green lighten-3';
      }
      else {
        return this.$vuetify.theme.dark ? '' : 'grey lighten-3';
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.statusclearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

/* Responsive Arrow Progress Bar 
    primary: '#545352',
    secondary: '#777777',
    accent: '#edbf3a',


.statusarrow-container {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}*/

.arrow-steps {
  display: flex;
}

.arrow-steps .step {
  flex-grow: 1;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: rgb(0, 0, 0);
  cursor: default;
  margin: 0 1px 0 0;
  padding: 10px 0px 10px 0px;
  float: left;
  position: relative;
  background-color: #bbb;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.arrow-steps .step a {
  color: #fff;
  text-decoration: none;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: "";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 17px solid #bbb;
  z-index: 2;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:last-child:after {
  /*no ending arrow*/
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

*.arrow-steps .step.done span:before {
  opacity: 1;
  content: "";
  position: absolute;
  top: -2px;
  left: -10px;
  font-size: 11px;
  line-height: 21px;
}

.arrow-steps .step.current {
  /* color: #000; */
  background-color: #edbf3a;
}

.arrow-steps .step.current a {
  /* color: #000; */
  text-decoration: none;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #edbf3a;
}

.arrow-steps .step.done {
  /* color: #fff; */
  background-color: #81C784;
}

.arrow-steps .step.done a {
  /* color: #fff; */
  text-decoration: none;
}

.arrow-steps .step.done:after {
  border-left: 17px solid #81C784;
}
</style>
